import React from "react"
import { Link } from "gatsby"
import Layout from "../Components/Layout"
import Box from "../Components/Box"
import TopIlliustration from "../assets/images/about-illiustration.jpg"
import Forest from "../assets/svg/forest.svg"

export default () => (
  <Layout>

    <h2 style={{paddingBottom:"96px"}}>Apie Projektą</h2>

    <div className="grid" >
      <div className="grid--cell w-50 about-f">
        <img src={Forest} alt="" />
      </div>
      <div className="grid--cell w-50 ">
        <div style={{margin:"auto"}}>
          <div className="index--about-project">
            <div className="text-container">
        <p>
          Projektą organizuoja <b>Žaliosios politikos institutas</b>. Mūsų tikslas – advokatauti už klimato krizės sprendimų integraciją į viešąją darbotvarkę, verslo praktiką ir visuomenės gyvenimą.
        </p>
        <p>
          Žalios politikos institutas nagrinėja žiedinės ekonomikos, atsakingo atliekų tvarkymo, atsinaujinančios energetikos, klimato kaitos ir atsakingo vartojimo temas. Mūsų komanda teikia įrodymais pagrįstus siūlymus, kaip praktiškai spręsti kylančius iššūkius ir advokatauja už pasiūlymų perkėlimą į politinį lygmenį.
        </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="grid m-reverse" >
      <div className="grid--cell w-50 ">
        <div style={{margin:"auto"}}>
          <div className="index--about-project">
            <div className="text-container">
        <p>
          <b>Nacionalinis aplinkosaugos egzaminas</b> – projektas, skirtas patikrinti savo aplinkosaugines žinias bei įgyti naujų klimato kaitos, žiedinės ekonomikos, rūšiavimo, energetikos bei gyvosios gamtos temomis.
        </p>
        <p>
          Egzamino klausimus bei edukacinį turinį dalyviams rengia kompetentinga ekspertų taryba, kurią sudaro specialistai iš akademinio, nevyriausybinio, verslo ir valstybinio sektorių.
        </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid--cell w-50 about-f" style={{alignItems: "end"}}>
        <img src={Forest} alt="" />
      </div>
    </div>


    <div className="grid bg-green" style={{padding:"48px", marginTop:"64px"}}>
      <strong>
        Registruokis į Nacionalinį aplinkosaugos egzaminą jau dabar ir pradėk gilinti savo žinias! Iki spalio 24 d. su egzamino dalyviais dalinsimės edukaciniu turiniu, kuris padės geriau pasiruošti egzaminui.
      </strong>
    </div>


    <div className="grid">
      <div className="grid--cell">
        <div>
    <div className="block-header-left ">
      <h3>Iškilo klausimų?</h3>
      <p>Mielai atsakysime į visus klausimus apačioje nurodytais kontaktais.</p>
    </div>
        </div>
      </div>
    </div>



      {/*<div className="text-container">*/}

      {/*  <h3>*/}
      {/*    Iškilo klausimų?*/}
      {/*  </h3>*/}
      {/*  <p>*/}
      {/*    Mielai atsakysime į visus klausimus apačioje nurodytais kontaktais.*/}
      {/*  </p>*/}
      {/*</div>*/}


    <div className="grid" style={{marginTop:"64px", display:"inline-flex", flexFlow:"wrap", justifyContent:"space-between"}}>
      <div className="grid--cell w-33 bg-green" style={{padding:"24px"}}>
        <p>Skambink:</p>
        <strong>+370 5 213 13 53 <br/> +370 698 11916 </strong>
      </div>
      <div className="grid--cell w-33 bg-green" style={{padding:"24px"}}>
        <p>Parašyk:</p>
        <strong>
          <a href="mailto:egzaminas@zaliojipolitika.lt" title="egzaminas@zaliojipolitika.lt">
            egzaminas@zaliojipolitika.lt
          </a>
          </strong>
      </div>
      <div className="grid--cell w-33 bg-green" style={{padding:"24px"}}>
        <p>Žiniasklaidai:</p>
        <strong>
          <a href="mailto:info@zaliojipolitika.lt " title="info@zaliojipolitika.lt">
            info@zaliojipolitika.lt
          </a>
        </strong>
      </div>
    </div>


  </Layout>
)
